<template>
  <v-card>
    <v-row class="mt-2 mx-2 pt-0">
      <v-col
        cols="12" sm="6"
        class="px-4 pt-2 mt-1 mb-0 pb-0"
      >
        <v-select
          v-model="selectedCategory"
          outlined
          dense
          :items="category_filter"
          label="Select Category"
        ></v-select>
      </v-col>
      <v-col
        cols="12" sm="6"
      >
        <v-select
          v-model="selectedTopUp"
          outlined
          dense
          item-value="id"
          item-text="username"
          :items="topup_list"
          label="Select TopUp"
        ></v-select>
      </v-col>
      <v-col
        cols="5"
        xs="5"
        class="text-left mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedStartDate"
          dense
          type="date"
          label="Start Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="5"
        xs="5"
        class="text-right mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedEndDate"
          dense
          type="date"
          label="End Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="2"
        xs="2"
        class="text-right mt-0 pt-0"
      >
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          icon small
          class="primary"
          @click="getTransactions()"
        >
          <font-awesome-icon icon="fa-solid fa-search"/>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-title class="">
      <span class="me-3">({{ transactions.length }}) Transactions</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <h3 class="ml-3">Total Deposit: {{ deposit_total }} F CFA </h3>
    <v-data-table
      :headers="headers"
      :items="transactions"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.username`]="{item}">
        <div class="d-flex flex-column" style="cursor: pointer"
          @click.stop="setDialog=true"
          @click="changeSwitch(item.id)"
        >
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</span>
          <small v-if="item.loan">{{ item.loan }}</small>
          <small v-else>{{ item.campaign }}</small>
        </div>
      </template>
      <template #[`item.amount`]="{item}">
        {{ item.amount }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[item.status]"
          class="font-weight-medium"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template #[`item.date_time`]="{item}">
        {{ item.date_time }}
      </template>
    </v-data-table>
    <v-dialog width="500px" v-model="setDialog"> 
        <v-card> 
          <v-card-title class=" white--text">{{ dialogTitle }}</v-card-title> 
          <v-card-text class="black--text pt-2">
            <span v-html="transactionDetails"></span>

            <v-text-field
              class="mt-2"
              v-model="amount"
              ref="amount"
              outlined
              dense
              label="Amount"
            ></v-text-field>
            <spinner v-if="loading" class="text-end"></spinner>
            <v-btn
              v-else color="primary" @click="changeTransactionAmount()" 
            > 
              Change 
            </v-btn> 
          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn color="success" text @click="setDialog = false" > Close </v-btn> 
          </v-card-actions> 
        </v-card> 
    </v-dialog> 
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Withdrawal: 'primary',
      Deposit: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      switch1: true,
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      deposit_total: '',
      transactions: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      selectedTopUp: null,
      topup_list: ["All"],
      selectedCategory: "",
      category_filter: ["Loans", "Campaigns"],

      setDialog: false,
      selectedTransaction: null,
      dialogTitle: "Set Status For Transaction",
      transactionDetails: "",
      amount: "",

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | List of Transactions"
      this.getTopUps()
  },

  methods:{
    changeSwitch(transaction_id){
      if (!this.selectedCategory || this.selectedCategory === ''){
        this.selectedCategory = 'Loans'
      }
      axios
        .get('/api/v1/manager/get/transaction/'+this.selectedCategory+'/'+transaction_id+'/')
        .then(response => {
          const username = response.data[0]["top_up_admin"]
          const amount = response.data[0]["amount"]
          this.selectedTransaction = transaction_id
          const campaign = response.data[0]["campaign_name"]
          const loan = response.data[0]["loan_name"]

          if (response.data[0]["campaign_name"]){
            this.transactionDetails = "Edit the amount of this deposit of amount <b>'"+amount+" F CFA'</b> on the campaign <b>'"+campaign+"'</b> deposited by <b>'"+username+"'</b>"
          }
          else{
            this.transactionDetails = "Edit the state of this deposit of amount <b>'"+amount+" F CFA'</b> on the loan <b>'"+loan+"'</b> deposited by <b>'"+username+"'</b>"
            console.log(response.data)
          }

          this.amount = response.data[0]["amount"]
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeTransactionAmount(){
      if (!this.amount || this.amount == "" || this.amount == 0){
        this.$store.commit('setSnackBarMessage', "Error: Please input a valid amount")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          
      }
      else if (!this.selectedCategory || this.selectedCategory === ''){
        this.selectedCategory = 'Loans'
      }
      else{
        this.loading = true
        let formData = new FormData()
        formData.append('amount', this.amount)
        formData.append('category', this.selectedCategory)

        axios
          .post('/api/v1/manager/change/transaction/deposit/'+this.selectedTransaction+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', "primary darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            this.getTransactions()
            this.setDialog = false
          })
          .catch(error => {
            this.setDialog = false
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
        this.loading = false
      }
    },

    getTopUps(){
      axios
        .get('/api/v1/manager/get/topups/')
        .then(response => {
          this.topup_list = response.data  // get the data and fill into campaigns
          console.log(this.topup_list)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getTransactions(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (!this.selectedTopUp|| this.selectedTopUp === ''){
        this.selectedTopUp = 'All'
      }
      if (!this.selectedCategory || this.selectedCategory === ''){
        this.selectedCategory = 'Loans'
      }
      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/get/transactions/deposit/'+this.selectedCategory+'/'+this.selectedTopUp+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.deposit_total = response.data["deposit_total"]
          this.transactions = response.data["response_data"]  // get the data and fill into campaigns
          console.log(this.transactions)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

  },
}
</script>
